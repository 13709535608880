<template>
  <div>
    <div class="Join base-height">
      <div class="login_left"></div>
      <div class="mainBox">
        <a href="/"
          ><img
            src="@/assets/images/reviewmind/logo2.png"
            alt="logo"
            class="bgLogo"
        /></a>
        <div class="form join">
          <h2>{{ $t("join_title") }}</h2>

          <fieldset>
            <legend>정보입력</legend>
            <div class="cover bg">
              <input
                type="text"
                v-model="username"
                :placeholder="$t('username')"
              />
            </div>

            <div class="cover bg">
              <input type="text" v-model="email" :placeholder="$t('email')" />
            </div>
            <div class="cover bg">
              <input
                type="text"
                v-model="mobileNo"
                :placeholder="$t('cellPhone')"
                :disabled="pwdEditFlag"
              />
              <button @click="requestCertNumber">{{ $t("send") }}</button>
            </div>
            <div v-show="veyfyCodeVisible" class="cover bg">
              <!-- <p>인증번호입력</p> -->
              <input type="text" v-model="certNumber" />
              <button @click="verifyNumber">{{ $t("confirm") }}</button>
            </div>
            <div class="cover bg">
              <input
                type="password"
                v-model="password"
                min="6"
                :placeholder="$t('joinPwd')"
              />
            </div>
            <div class="cover bg">
              <input
                type="password"
                v-model="passwordConfirm"
                :placeholder="$t('checkPwd')"
              />
            </div>
            <p>
              <label>
                <input v-model="termOfUseFlag" type="checkbox" />
                <a href="#">
                  <b class="link" @click="showAuth(1)">{{ $t("joinTerms") }}</b>
                </a>
              </label>
            </p>
            <p>
              <label>
                <input v-model="personDataCollectAgreeFlag" type="checkbox" />
                <a href="#">
                  <b class="link" @click="showAuth(2)">{{
                    $t("joinPrivacy")
                  }}</b>
                </a>
              </label>
            </p>
            <button class="btn full primary" @click="submit()">
              {{ $t("join") }}
            </button>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="modal" v-show="visible">
      <div class="modal_cover"></div>
      <div class="modal_content">
        <div class="m_header">
          <div>{{ modalTitle }}</div>
          <button class="close" @click="closeModal()">
            <span class="material-icons"> close </span>
          </button>
        </div>
        <div class="m_body" v-show="termsShow">
          <UseTerms />
        </div>
        <div class="m_body" v-show="privacyShow">
          <TermsPrivacy />
        </div>
        <div class="m_footer">
          <button class="btn" @click="closeModal()">확인</button>
        </div>
      </div>
    </div>

    <!-- <transition name="fade">
      <div class="dim on">
        <AuthCode />
      </div>
    </transition> -->
    <!-- 3. 컴포넌트 임포트 네이밍 호출 -->
  </div>
</template>
<script>
import sha256 from "js-sha256";
import {
  createRegister,
  createJoinAuth,
  createCertNumber,
  verifyMobile,
} from "@/api/member";
import { format } from "@/mixins/format";
import moment from "moment";
import TermsPrivacy from "@/components/terms/TermsPrivacy";
import { GOOGLE_CLIENT_ID } from "@/config/key";
import UseTerms from "@/components/terms/UseTerms";
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";
import { verifyPassword } from "@/mixins/verifyPassword";
import { baseUrl } from "@/config/env";
import axios from "axios";
export default {
  components: { TermsPrivacy, UseTerms } /* 2. 컴포넌트 선언 */,
  mixins: [format, setNavbar, setFooter, verifyPassword],
  data() {
    return {
      moment: moment,
      showSnackbar: false,
      baseUrl: baseUrl,
      visible: false,
      client_id: GOOGLE_CLIENT_ID,
      veyfyCodeVisible: false,
      email: "",
      mobileNo: "",
      username: "",
      birthday: "",
      certNumber: "",
      residentRegistrationNumber: "",
      password: "",
      passwordConfirm: "",
      personDataCollectAgreeFlag: false,
      termOfUseFlag: false,
      agree: "",
      auth: false,
      verify: false,
      modalTitle: "",
      privacyShow: false,
      termsShow: false,
      pwdEditFlag: false,
    };
  },
  mounted() {
    this.init();
    this.setNavbar(false);
    this.setFooter(false);
  },
  methods: {
    showAuth(n) {
      if (n == 1) {
        this.visible = true;
        this.termsShow = true;
        this.modalTitle = this.$t("term");
      } else {
        this.visible = true;
        this.privacyShow = true;
        this.modalTitle = this.$t("privacy");
      }
    },
    closeModal() {
      this.visible = false;
      this.termsShow = false;
      this.privacyShow = false;
    },
    getBirthdayMask(val) {
      let res = this.getBirthday(val);
      this.residentRegistrationNumber = res;
    },
    requestCertNumber() {
      if (this.mobileNo == "") {
        return alert(this.$t("checkNum"));
      }
      let data = {
        mobileNo: this.mobileNo,
      };
      this.veyfyCodeVisible = true;
      createCertNumber(data).then((res) => {
        if (res.data.status == 200) {
          return alert(this.$t("sendCertNum"));
        } else {
          alert(res.data.message);
        }
      });
    },
    verifyNumber() {
      let data = {
        mobileNo: this.mobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.verify = true;
          this.veyfyCodeVisible = false;
          this.pwdEditFlag = true;
          return alert(this.$t("authOk"));
        } else if (res.data.status == 401) {
          this.verify = false;
          return alert(this.$t("checkCertNum"));
        } else if (res.data.status == 409) {
          this.verify = false;
          return alert(this.$t("requsetCertNum"));
        }
      });
    },
    init() {
      window.gapi.load("auth2", () => {
        window.gapi.auth2.init({
          client_id: this.client_id,
          scope: "profile email",
        });
      });
    },
    googleLogin() {
      const gauth = window.gapi.auth2.getAuthInstance();

      gauth.signIn().then((googleUser) => {
        let id_token = googleUser.getAuthResponse().id_token;
        axios({
          method: "POST",
          url: baseUrl + "/auth/google",
          data: {
            tokenId: id_token,
          },
        }).then((res) => {
          if (res.data.status === 200) {
            let data = {
              accesstoken: res.data.accesstoken,
              email: res.data.userInfo.email,
              username: res.data.userInfo.username,
            };
            this.$store.dispatch("LOGIN", data);
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      });
    },
    kakaoLogin() {
      window.Kakao.Auth.loginForm({
        scope: "account_email, profile",
        throughTalk: true,
        success: this.Login,
      });
    },
    Login(authObj) {
      axios
        .post(this.baseUrl + `/auth/kakao/callback`, {
          accesstoken: authObj.access_token,
        })
        .then((res) => {
          let data = {
            accesstoken: res.data.accesstoken,
            email: res.data.email,
          };
          this.$store.dispatch("LOGIN", data);
        })
        .catch((err) => {
          window.console.log(err);
        });
    },
    joinAuth(email) {
      let data = {
        email: email,
      };
      createJoinAuth(data).then((res) => {
        if (res.status == 200) {
          // 회원 정보가 없을 경우
          this.$router.push("/joinDone");
        } else {
          // 회원 정보가 있을 경우
          let message = res.data.message;
          alert(message);
        }
      });
    },
    submit() {
      let result = this.verifyPassword(this.password);
      if (result == false) {
        return;
      }

      if (this.verify == false) {
        return alert("휴대폰 인증을 해주세요.");
      }
      if (this.username == "") {
        return alert(this.$t("inputName"));
      } else if (this.email == "") {
        return alert("이메일을 입력해 주세요.");
      } else if (this.emailValidate(this.email) == false) {
        return alert("이메일 형식이 맞지 않습니다.");
      } else if (this.mobileNo == "") {
        return alert("휴대폰 번호를 입력해 주세요.");
      } else if (this.password == "" || this.passwordConfirm == "") {
        return alert(this.$t("inputPassword"));
      } else if (this.password != this.passwordConfirm) {
        return alert(this.$t("checkPassword"));
      } else if (!this.termOfUseFlag) {
        return alert(this.$t("checkTerms"));
      } else if (!this.personDataCollectAgreeFlag) {
        return alert(this.$t("checkPrivacy"));
      }

      let data = {
        email: this.email,
        mobileNo: this.mobileNo,
        username: this.username,
        password: sha256(this.password),
        personDataCollectAgreeFlag: this.personDataCollectAgreeFlag,
        termOfUseFlag: this.termOfUseFlag,
      };
      createRegister(data).then((res) => {
        if (res.status == 200) {
          this.$router.push("/joinDone");
        } else {
          alert(this.$t("joinCheck"));
        }
      });
    },
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
